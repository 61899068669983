<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <!-- General Coupon -->
        <b-card>
          <div slot="header">
            <strong>General Vendor Coupon</strong>
            <small>Form</small>
          </div>

          <b-form v-on:submit.prevent.self="submitGeneral">
            <b-row></b-row>
            <b-row>
              <b-col sm="4">
                <b-form-group>
                  <label for="valueType">Cinema Name</label>
                  <b-form-select
                    required
                    v-model="couponObject.selectedCinema"
                    :plain="true"
                    value-field="CinemaID"
                    text-field="CinemaName"
                    :options="couponObject.cinemaList"
                  >
                    <template slot="first">
                      <option :value="'0'" disabled>-- Select Cinema --</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group>
                  <label for="shopname">Shop Name</label>
                  <b-form-input type="text" v-model="coupon.ShopName" placeholder="Shop Name"></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="4">
                <b-form-group>
                  <label for="shopcategory">Shop Category</label>
                  <b-form-textarea
                    v-model="coupon.ShopCategory"
                    placeholder="Shop Category"
                    :rows="3"
                    :max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="12">
                <div
                  class="row"
                  v-for="(item, _index) in coupon.CouponOffering"
                  :key="_index"
                  :class="item"
                >
                  <div class="col-md-4">
                    <b-form-group>
                      <label for="Offering">Offering</label>
                      <b-form-textarea
                        required
                        v-model="item.Offering"
                        id="Offering"
                        placeholder="Offering"
                        :rows="3"
                        :max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </div>
                  <div class="col-md-3">
                    <b-form-group>
                      <label for="StartDate">Start Date</label>
                      <b-form-input
                        type="date"
                        required
                        v-model="item.StartDate"
                        id="StartDate"
                        placeholder="StartDate"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-3">
                    <b-form-group>
                      <label for="EndDate">End Date</label>
                      <b-form-input
                        type="date"
                        required
                        v-model="item.EndDate"
                        id="EndDate"
                        placeholder="EndDate"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-1">
                    <b-form-group>
                      <label for="EndDate">IsActive</label>
                      <c-switch
                        class="mx-1"
                        color="primary"
                        @change.native="changeStatus(item.ID)"
                        v-model="item.OfferingIsActive"
                        variant="3d"
                        label
                        v-bind="labelIcon"
                      />
                    </b-form-group>
                  </div>
                  <div class="col-md-1">
                    <i
                      v-if="_index === 0"
                      style="margin-top: 1rem;"
                      class="fa fa-plus-square-o text-success mt-3 mx-2"
                      @click="addOffering(_index)"
                    ></i>
                    <i
                      v-if="_index !== 0"
                      style="margin-top: -15px;"
                      class="fa fa-minus-square-o text-danger mx-2"
                      @click="removeOffering(_index)"
                    ></i>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="6">
                <b-button type="submit" size="sm" variant="primary">
                  <i class="fa fa-dot-circle-o"></i>
                  {{ commontext.submitButton }}
                </b-button>
              </b-col>

              <b-col sm="6">
                <b-button @click="resetForm" size="sm" variant="success">
                  <i class="fa fa-dot-circle-o"></i> Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <!-- /General Coupon -->

        <!-- List of Coupons -->
        <b-card>
          <b-row>
            <b-col sm="4">
              <b-form-group>
                <label for>Selected Coupon Type</label>
                <b-form-select
                  required
                  v-model="couponObject.selectedCoupon"
                  id="cinemas"
                  :plain="true"
                  :options="couponObject.couponDropDownList"
                  @change.native="getCoupons($event.target.value)"
                >
                  <!-- @change.native="onChangeCinemaDropdwon($event.target.value)" -->
                </b-form-select>
              </b-form-group>
            </b-col>
            <!-- <b-col sm="4">
              <b-form-group>
                  <label for=""></label>
                  <b-button v-on:click="onFilterSubmit()" type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> Submit</b-button>
              </b-form-group>
            </b-col>-->
          </b-row>

          <b-card-header>
            <i class="icon-menu mr-1"></i>List of Coupons
          </b-card-header>
          <b-card-body>
            <v-client-table
              :columns="columns"
              :data="data"
              :options="options"
              :theme="theme"
              id="dataTable"
            >
              <div slot="CouponOffering" slot-scope="props">
                <v-client-table
                  :columns="OfferingColumn"
                  :data="props.row.Offering"
                  :options="OfferingOptions"
                  :theme="theme"
                  id="dataTable1"
                >
                  <div slot="IsActive" slot-scope="props">
                    <c-switch
                      class="mx-1"
                      color="primary"
                      v-model="props.row.IsActive"
                      variant="3d"
                      label
                      v-bind="labelIcon"
                      @change.native="changeStatus(props.row.CouponID)"
                    />
                  </div>
                </v-client-table>
              </div>
              <div slot="IsActive" slot-scope="props">
                <c-switch
                  class="mx-1"
                  color="primary"
                  v-model="props.row.IsActive"
                  variant="3d"
                  label
                  v-bind="labelIcon"
                  @change.native="changeStatus(props.row.CouponID)"
                />
              </div>
              <div slot="Action" slot-scope="props">
                <i class="fa fa-edit" @click="editCoupon(props.row.ID)"></i>
              </div>
            </v-client-table>
          </b-card-body>
        </b-card>
        <!-- /List of Coupons -->
      </b-col>
    </b-row>
    <b-modal
      id="ModalConfirmationOnSubmit"
      ref="ModalConfirmationOnSubmit"
      @ok="handleModalOkay"
      :title="modalObject.title"
      class="center-footer"
      footer-class="justify-content-center border-0"
      cancel-variant="outline-danger"
      ok-variant="outline-success"
      centered
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <div>{{ modalObject.message }}</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import UploadService from "@/services/UploadService";
import CinemaService from "@/services/CinemaService";
import MasterService from "@/services/MasterService";
import { VueEditor } from "vue2-editor";
import { Event } from "vue-tables-2";

export default {
  name: "CouponCode",
  components: {
    cSwitch,
    VueEditor,
    Event
  },
  data() {
    return {
      // Saif
      commontext: {
        submitButton: "Add Coupon"
      },
      OfferingFormatList: {
        value: "",
        IsDisabled: false,
        Offering: "",
        ID: 0,
        StartDate: "",
        EndDate: "",
        OfferingIsActive: true
      },
      couponObject: {
        cinemaList: "",
        selectedCinema: "0",
        freeOutOf: "",
        free: "",
        selectedCoupon: "All",
        couponDropDownList: [
          {
            text: "All",
            value: "All"
          },
          {
            text: "General",
            value: "General"
          },
          {
            text: "Bank",
            value: "Bank"
          },
          {
            text: "Unique",
            value: "Unique"
          }
        ]
      },

      // Saif

      coupon: {
        ID: null,
        ShopName: "",
        ShopCategory: "",
        CinemaID: "",
        Expiry: "",
        IsActive: true,
        CouponOffering: [
          {
            value: "",
            IsDisabled: false,
            Offering: "",
            ID: 0,
            StartDate: "",
            EndDate: "",
            OfferingIsActive: true
          }
        ]
      },
      CouponOffering: [
        {
          value: "",
          IsDisabled: false,
          Offering: "",
          ID: 0,
          StartDate: "",
          EndDate: "",
          OfferingIsActive: true
        }
      ],
      CouponContainer: [],
      DownloadFile: "",
      Today: this.formatDate(new Date()),
      GValueType: "Percentage",
      // UValueType: 'Percentage',
      columns: [
        "ID",
        "CinemaID",
        "ShopName",
        "ShopCategory",
        "CouponOffering",
        "IsActive",
        "Action"
      ],
      OfferingColumn: [
        "MallID",
        "Offercode",
        "Offer",
        "StartDate",
        "EndDate",
        "IsActive"
      ],
      OfferingOptions: {
        filterable: false,
        headings: {
          MallID: "Mall ID",
          Offercode: "Offer Code",
          Offer: "Offer",
          StartDate: "Start Date",
          EndDate: "End Date",
          IsActive: "Is Active"
        }
      },
      data: [],
      options: {
        headings: {
          ID: "ID",
          CinemaID: "Cinema ID",
          ShopName: "Shop Name",
          ShopCategory: "ShopCategory",
          CouponOffering: "Coupon Offering",
          IsActive: "Is Active",
          Action: "Edit"
        },
        sortable: ["ID", "CinemaID", "ShopName", "ShopCategory"],
        filterable: ["ID", "CinemaID", "ShopName", "ShopCategory"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  beforeMount() {
    this.getCinemasDDL(0);
  },
  mounted() {
    this.getMallCoupons();
    // this.CouponOffering.push(this.OfferingFormatList);
  },
  methods: {
    getCinemasDDL: function(OrgID) {
      this.couponObject.cinemaList = [];
      // let res = await CinemaService.getCinemasDDL(0);
      let payload = {
        OrgID
      };
      CinemaService.getCinemasDDL(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CinemaList.length > 0) {
            this.couponObject.cinemaList = data.CinemaList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    addOffering: function(currentIndex) {
      console.log("Current Index", currentIndex);
      console.log(this.CouponOffering);
      this.coupon.CouponOffering.push({
        value: "",
        IsDisabled: false,
        Offering: "",
        ID: 0,
        StartDate: "",
        EndDate: "",
        OfferingIsActive: true
      });
    },
    removeOffering: function(currentIndex) {
      //   console.log(currentIndex);
      //   console.log(this.CouponOffering[currentIndex]);
      this.coupon.CouponOffering.splice(currentIndex, 1);
    },
    getCountryData: function() {
      this.couponObject.countryList = [];
      // let res = await MasterService.getCountriesDDL();
      MasterService.getCountriesDDL()
        .then(response => {
          const { data } = response;
          if (data.Status && data.CountryList.length > 0) {
            this.couponObject.countryList = data.CountryList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    resetForm: function() {
      this.commontext.submitButton = "Add Coupon";

      this.couponObject.selectedCountry = "0";
      this.couponObject.freeOutOf = "";
      this.couponObject.free = "";
      this.coupon = {
        CouponID: null,
        Name: "",
        Title: "",
        Image: "",
        Description: "",
        CouponIsActive: true,
        TermsAndConditions: "",
        StartDate: "",
        EndDate: "",
        NumberOfCoupons: "",
        Value: "",
        Percentage: true,
        CartValue: "",
        MaxValue: "",
        IsUnique: true,
        IsBin: false,
        BankName: "",
        CouponFile: ""
      };
      var previewDiv = document.getElementById("previewGeneral");
      var previewImg = "";
      var previewImg = document.createElement("img");
      while (previewDiv.firstChild) {
        previewDiv.removeChild(previewDiv.firstChild);
      }
      this.$refs.fileinputGen.reset();
      if (this.coupon.CouponFile != "") {
        this.$refs.fileinput.reset();
      }
    },
    getMallCoupons: function() {
      this.data = [];
      let payLoad = {};

      CinemaService.getMallCoupons(payLoad)
        .then(response => {
          const { data } = response;
          if (data.Status && data.Promotions.length > 0) {
            this.data = data.Promotions;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log(error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. Kindly contact your administrator"
          );
        });
    },
    getCoupons: function(coupon) {
      // let res = await CinemaService.getCoupons({ coupon: name });
      // this.data = res.data;
      this.data = [];
      let payload = { Filter: coupon };
      CinemaService.getCoupons(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CouponList.length > 0) {
            this.data = data.CouponList.reverse();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    async uploadImage(image, IsUnique) {
      var formdata = new FormData();

      var file = image.files[0];

      formdata.append("assets/coupon", image.files[0]);
      formdata.append("rename", this.coupon.Name);
      let res = await CinemaService.uploadFiles(formdata);
      if (IsUnique) {
        this.uniqueCoupon.Image = res.data;
      } else {
        this.coupon.Image = res.data;
      }
      var previewDiv =
        IsUnique == true
          ? document.getElementById("previewUnique")
          : document.getElementById("previewGeneral");
      var previewImg = "";
      var previewImg = document.createElement("img");
      while (previewDiv.firstChild) {
        previewDiv.removeChild(previewDiv.firstChild);
      }
      previewDiv.appendChild(previewImg);
      previewImg.src = window.URL.createObjectURL(image.files[0]);
      previewImg.classList.add("img-fluid");
    },
    validation: function() {
      // if (this.country.CountryName == "") {
      //   this.showMessageSnackbar("Country Name Cannot be empty.");
      //   return false;
      // } else if (this.country.Time_Offset == "") {
      //   this.showMessageSnackbar("Country Time Offset Cannot be empty.");
      //   return false;
      // } else if (this.country.ISOCode == "") {
      //   this.showMessageSnackbar("Country ISO Code Cannot be empty.");
      //   return false;
      // } else if (this.country.Currency_ISOCode == "") {
      //   this.showMessageSnackbar("Country Currency Cannot be empty.");
      //   return false;
      // } else {
      //   return true;
      // }
      return true;
    },
    submitGeneral: function() {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      let Obj = Object.assign({}, this.coupon);
      /*      this.coupon.Percentage = this.GValueType == "Percentage" ? true : false;
      if (!this.coupon.IsBin) {
        this.coupon.BankName = "";
        this.coupon.CouponFile = "";
      }
      let Obj = Object.assign({}, this.coupon);
      Obj["Free"] = this.couponObject.free;
      Obj["FreeOutOf"] = this.couponObject.freeOutOf;
      Obj["CountryID"] =
        this.couponObject.selectedCountry == "0"
          ? ""
          : this.couponObject.selectedCountry;
*/
      Obj["CinemaID"] = this.couponObject.selectedCinema;
      Obj["ShopName"] = this.coupon.ShopName;
      Obj["ShopCategory"] = this.coupon.ShopCategory;
      Obj["Offering"] = this.coupon.CouponOffering;

      // Whenevrer Free Change the Below Code
      if (Obj["ID"] != null) {
        Obj["Flag"] = 2;
      } else {
        Obj["ID"] = undefined;
        Obj["Flag"] = 1;
      }

      console.log("ObjObjObjObjObjObjObjObjObjObjObj", Obj);

      // let res = await CinemaService.addCoupon(Obj);
      CinemaService.addMallCoupon(Obj)
        .then(response => {
          const { data } = response;
          data.Message ? this.showMessageSnackbar(data.Message) : "";
          if (data.Status) {
            this.resetForm();
            this.getMallCoupons();
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editCoupon: function(ID) {
      console.log(this.data[ID]);
      // let res = await CinemaService.getCouponByID(CouponID);
      /*let payload = {
        CouponID
      };
      CinemaService.getCouponByID(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CouponList.length > 0) {
            this.commontext.submitButton = "Update Coupon";

            // if(IsUnique == true) {
            //   this.uniqueCoupon = res.data[0];
            //   this.GValueType = this.uniqueCoupon.Percentage == true ? 'Percentage' : 'Currency';
            //   var previewDiv = document.getElementById('previewUnique')
            // } else {
            this.coupon = data.CouponList[0];

            this.getCountryData();
            this.couponObject.selectedCountry = data.CouponList[0].CountryID;
            this.couponObject.freeOutOf = data.CouponList[0].FreeOutOf;
            this.couponObject.free = data.CouponList[0].Free;

            this.DownloadFile = data.CouponList[0].DownloadFile;
            this.UValueType =
              this.coupon.Percentage == true ? "Percentage" : "Currency";
            var previewDiv = document.getElementById("previewGeneral");
            // }
            var previewImg = "";
            var previewImg = document.createElement("img");
            while (previewDiv.firstChild) {
              previewDiv.removeChild(previewDiv.firstChild);
            }
            previewDiv.appendChild(previewImg);
            previewImg.src = data.CouponList[0].BaseURL + this.coupon.Image;
            previewImg.classList.add("img-fluid");
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });*/
    },
    changeStatus: function(CouponID) {
      let payload = {
        TableName: "Coupons",
        ID
      };
      MasterService.activateOrDeactivate(payload)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    formatDate: function(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    editCouponIsActive: function(SorE) {
      if (
        this.coupon.StartDate != "" &&
        this.coupon.EndDate < this.coupon.StartDate
      ) {
        this.showMessageSnackbar(
          "Please choose End Date greater than Start Date"
        );
        this.coupon.EndDate = "";
      }
      if (this.coupon.StartDate != "" && this.coupon.EndDate != "") {
        this.Today = this.formatDate(new Date());
        this.coupon.CouponIsActive = this.coupon.EndDate > this.Today;
      }
    },
    uploadFile: function(fileXLS) {
      let self = this;
      var formdata = new FormData();

      formdata.append("assets/coupon", fileXLS.files[0]);
      formdata.append("type", "xls");
      formdata.append("rename", self.coupon.Name);
      // let res = await CinemaService.uploadFiles(formdata);
      UploadService.uploadFiles(formdata)
        .then(response => {
          const { data } = response;
          if (data && data.Status) {
            self.coupon.CouponFile = data.Path;
          } else {
            self.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          self.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // async submitUnique() {
    //   this.uniqueCoupon.Percentage = this.UValueType == 'Percentage' ? true : false
    //   console.log('this.uniqueCoupon :', this.uniqueCoupon);
    //     let res = await CinemaService.addCoupon(this.uniqueCoupon)
    //     if(res.data.Status == true) {
    //         this.uniqueCoupon = {
    //           CouponID: null,
    //           Name: '',
    //           Title: '',
    //           Image: '',
    //           Description: '',
    //           CouponIsActive: true,
    //           TermsAndConditions: '',
    //           StartDate: '',
    //           EndDate: '',
    //           NumberOfCoupons: '',
    //           Value: '',
    //           Percentage: true,
    //           CartValue: '',
    //           MaxValue: '',
    //           IsUnique: true
    //         }
    //         var previewDiv = document.getElementById('previewUnique')
    //         var previewImg = ''
    //         var previewImg = document.createElement('img');
    //         while (previewDiv.firstChild) {
    //             previewDiv.removeChild(previewDiv.firstChild);
    //         }
    //         this.$refs.fileinputUni.reset();
    //         this.getCoupons();
    //     }
    //     var x = document.getElementById("snackbar");
    //     // Add the "show" class to DIV
    //     x.className = "show";
    //     x.innerHTML =res.data.Message
    //     // After 3 seconds, remove the show class from DIV
    //     setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    // },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>

<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
